import React from 'react';

// import portrait from './portrait.JPG';
import portrait from './new_portrait.jpeg';
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Helmet } from "react-helmet";
import Media from 'react-bootstrap/Media'


import './App.css'

function HomePage() {
  return (
    <>
      <Helmet>
        <title>Isabelle Kohout</title>
      </Helmet>
      <Row className="pt-5">
        <Col md={{ span: 10, offset: 1 }} className='fade-in '>
          <Media className='p-2'>
            <img
              width={200}
              height={200}
              className="mr-3 rounded-circle"
              src={portrait}
              alt="Picture of Izzy"
            />
            <Media.Body>
              <h1 class="header-text heading">👋 hey there, I'm Izzy!</h1>
            </Media.Body>
          </Media>
          <h4 class="sub-header-text">I'm a tech person, obsessive reader & creative based in 📍Sydney. Currently working on AI products at Atlassian.</h4>
          <p className='content-text'>I think technology has the potential to make the world a far better, more equitable place.</p>
          <p className='content-text'>I also believe that it's far from neutral, and can be a multiplier on a host of existing social problems, so I’m passionate about creating things that are solving the right problems - what we <span class = 'bold'>should</span> build, as opposed to just what we <span class = 'bold'>could.</span></p>
          <p className='content-text'>I love 📖 books, 💡 learning, ✍️ writing, and 🎵 theatre. </p>
          {/* <h4>My background...</h4>
          <p className='content-text'>🤓 Right now, I’m an Associate Product Manager at Australia’s biggest tech company, <a href='https://www.atlassian.com/' target='_blank'>Atlassian,</a> building data experiences in Jira to help teams work better together. I’m soaking up as much as I can, learning how to build and scale great products and get the most out of high-performing teams. </p>
          <p className='content-text'>🛠 I have degrees from the University of Sydney in Mechatronic Engineering (Honours) and Computer Science. During my studies I developed a particular interest in ethical AI and its implications for humanity’s future (my book recommendations on this topic: <a href='https://www.google.com.au/books/edition/The_Alignment_Problem/TdL2DwAAQBAJ?hl=en&gbpv=1&printsec=frontcover' target='_blank'>the Alignment Problem</a>, <a href='https://books.google.com.au/books/about/Weapons_of_Math_Destruction.html?id=NgEwCwAAQBAJ&redir_esc=y' target='_blank'>Weapons of Math Destruction</a>, and <a href='https://books.google.com.au/books/about/Life_3_0.html?id=3_otDwAAQBAJ&redir_esc=y' target='_blank'>Life 3.0</a>). I got to write my Honours thesis on Fairness in Algorithmic Hiring.</p>
          <p className='content-text'>🌺 During uni, I also cofounded a (now dormant) startup, building a <a href='https://www.poppycareers.com/' target='_blank'>digital mentor</a> to help young women discover and explore different STEM careers. I learnt a tonne about product-market fit, the benefits of a diverse founding team, and the importance of starting with a specific problem. </p> */}
        </Col>
      </Row>
    </>
  );
}

export default HomePage;

