import React from "react";

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import {Link} from 'react-router-dom'

import linkedin from './linkedin-logo.png'
import email from './email.png'
import twitter from './twitter-sign.png'

import './App.css'


function Footer() {
    return (
        <>
        <div className='footer'>
                <Row className='d-flex justify-content-center'>
                    <Nav variant="pills" defaultActiveKey="/" className="justify-content-center">
                        <Nav.Item>
                            <a href='https://www.linkedin.com/in/isabellekohout/' target="_blank"><img className='social-logo' src={linkedin}/></a>
                        </Nav.Item>
                        <Nav.Item>
                            <a href='mailto: izzykohout@gmail.com' target="_blank"><img className='email-logo' src={email}/></a>
                        </Nav.Item>
                        <Nav.Item>
                            <a href='https://twitter.com/izzykohout' target="_blank"><img className='twitter-logo' src={twitter}/></a>
                        </Nav.Item>
                    </Nav>
                </Row>
                <Row className='d-flex justify-content-center'>                
                </Row>

            </div>
        </>
    );
}

export default Footer;