import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import {Link} from 'react-router-dom'

import linkedin from './linkedin-logo.png'
import email from './email.png'
import twitter from './twitter-sign.png'


import './App.css'

function Header() {
    return (
        <>
            <Row className='d-flex justify-content-center p-3'>
                <Nav variant="pills" defaultActiveKey="/" className="justify-content-center">
                    <Nav.Item>
                        <Nav.Link className='nav-text' as={Link} eventKey="0" to="/">👩🏻 about me</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='nav-text' as={Link} eventKey="1" to="/work">💪🏻 my work</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='nav-text' as={Link} eventKey="2" to="/writing">📚 reading & writing</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className='nav-text' as={Link} eventKey="3" to="/resources">🔗 resources</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item> */}
                        {/* <Nav.Link className='nav-text' as={Link} eventKey="3" to="/talks">💬 my talks</Nav.Link> */}
                    {/* </Nav.Item> */}
                </Nav>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Nav variant="pills" defaultActiveKey="/" className="justify-content-center">
                    <Nav.Item>
                        <a href='https://www.linkedin.com/in/isabellekohout/' target="_blank"><img className='social-logo' src={linkedin}/></a>
                    </Nav.Item>
                    <Nav.Item>
                        <a href='mailto: izzykohout@gmail.com' target="_blank"><img className='email-logo' src={email}/></a>
                    </Nav.Item>
                    <Nav.Item>
                        <a href='https://twitter.com/izzykohout' target="_blank"><img className='twitter-logo' src={twitter}/></a>
                    </Nav.Item>
                </Nav>
            </Row>
        </>
    );
}

export default Header;

