import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Media from 'react-bootstrap/Media'
import { Helmet } from "react-helmet";

import './App.css'
import atlassian from './atlassian.png'
import poppy from './poppy.png'
import pev from './pev.jpg'
import usyd from './usyd.jpg'
import forme from './forme.jpeg'
import google from './google.jpg'

function Work() {
  return (
    <>
      <Helmet>
        <title>Isabelle Kohout - Work</title>
      </Helmet>
      <Row className="pt-5">
        <Col md={{ span: 10, offset: 1 }} className='fade-in'>
        <h1 class="header-text">work</h1>
        <Media className='p-2'>
              <img
                width={70}
                height={70}
                className="mr-3, work-icon"
                src={atlassian}
                alt="Atlassian logo"
              />
              <Media.Body>
                <h5>Product Manager @ Atlassian</h5>
                <h7>March 23' - Present</h7>
                <p className='content-text'>
                  Building AI features across the Atlassian product suite to make work more efficient, collaborative, and fun!   
                </p>
              </Media.Body>
          </Media>
          <Media className='p-2'>
              <img
                width={70}
                height={70}
                className="mr-3, work-icon"
                src={atlassian}
                alt="Atlassian logo"
              />
              <Media.Body>
                <h5>Associate Product Manager @ Atlassian</h5>
                <h7>Jan 22' - March '23</h7>
                <p className='content-text'>
                  Lead PM driving the 'insights' feature in Jira Software to help teams work better together and supercharge their software delivery efficiency.
                </p>
              </Media.Body>
            </Media>
            <Media className='p-2'>
              <img
                width={70}
                height={70}
                className="mr-3, work-icon"
                src={atlassian}
                alt="Atlassian logo"
              />
              <Media.Body>
                <h5>Product Management Intern @ Atlassian</h5>
                <h7>Nov 20' - Aug 21'</h7>
                <p className='content-text'>
                  Delivered linking features to the web & mobile editor to help users find information easily. Helped to define the MVP and GTM strategy for <a href='https://www.atlassian.com/software/confluence/whiteboards'>Atlassian Whiteboards.</a>
              </p>
              </Media.Body>
            </Media>
            <Media className='p-2'>
              <img
                width={70}
                height={70}
                className="mr-3, work-icon"
                src={poppy}
                alt="Poppy Careers logo"
              />
              <Media.Body>
                <h5>Cofounder @ Poppy Careers</h5>
                <h7> Jan '20 - Aug 21'</h7>
                <p className='content-text'>
                  Designed and built a <a href="https://www.poppycareers.com/" target="_blank">platform</a> to help young women's paths into STEM be as clear and inspiring as they should be. Worked across marketing, partnerships, product and engineering. 
              </p>
              </Media.Body>
            </Media>
          <Media className='p-2'>
            <img
              width={70}
              height={70}
              className="mr-3, work-icon, border"
              src={pev}
              alt="Project Everest Ventures logo"
            />
            <Media.Body>
              <h5>Team Leader @ Project Everest Ventures</h5>
              <h7>Sep 19' - Mar 20'</h7>
              <p className='content-text'>
                Led teams to develop product solutions to challenges in the health and agriculture space in Malawi, Africa. Managed projects, products, risk and team dynamics. 
              </p>
            </Media.Body>
          </Media>
        </Col>
      </Row>
      <Row className="pt-4">
        <Col md={{ span: 10, offset: 1 }}>
        <h1 class="header-text">education & training</h1>
          <Media className='p-2'>
            <img
              width={70}
              height={70}
              className="mr-3, work-icon"
              src={usyd}
              alt="University of Sydney logo"
            />
            <Media.Body>
              <h5>Bachelor of Mechatronic Engineering (Advanced) @ University of Sydney (First Class Hons.)</h5>
              <h7>17'-21'</h7>
              <p className='content-text'>
               Developed a keen interest in AI & ML and the ways it can both perpetuate and alleviate social inequality. Wrote my Honours thesis on bias in ML-powered hiring algorithms, and investigated ways to make them more fair.
              </p>
            </Media.Body>
          </Media>
          <Media className='p-2'>
            <img
              width={70}
              height={70}
              className="mr-3, work-icon"
              src={usyd}
              alt="University of Sydney logo"
            />
            <Media.Body>
              <h5>Bachelor of Computer Science @ University of Sydney</h5>
              <h7>18'-21'</h7>
              <p className='content-text'>
                Studied web technologies, AI/ML, systems programming, data structures and algorithms, + more. 
              </p>
            </Media.Body>
          </Media>
          {/* <Media className='p-2'>
            <img
              width={70}
              height={70}
              className="mr-3, work-icon"
              src={google}
              alt="google logo"
            />
            <Media.Body>
              <h5>Data Structures & Algorithms @ Google GetAhead+</h5>
              <h7>Sep - Oct 20'</h7>
              <p className='content-text'>
              Participated in Google's GetAhead+ program that gives participants practical technical experience in data structures, algorithm design and optimisation.
              </p>
            </Media.Body>
          </Media> */}
          <Media className='p-2'>
            <img
              width={70}
              height={70}
              className="mr-3, icon-no-border"
              src={forme}
              alt="Forme Education logo"
            />
            <Media.Body>
              <h5>Certificate IV in Leadership and Management @ Orion Training</h5>
              <h7>Aug 20'</h7>
              <p className='content-text'>Completed my Cert IV through recognition of my experiences leading teams at Project Everest Ventures.</p>
            </Media.Body>
          </Media>
        </Col>
      </Row>
      {/* <Row className="pt-4">
        <Col md={{ span: 8, offset: 2 }}>
        <h1 class="header-text">projects I'm proud of</h1>
        <Media className='p-2'>
            <img
              width={70}
              height={70}
              className="mr-3, work-icon"
              src={usyd}
              alt="University of Sydney logo"
            />
            <Media.Body>
              <h5>Bachelor of Computer Science @ University of Sydney</h5>
              <h7>18'-21'</h7>
              <p>
              </p>
            </Media.Body>
          </Media>
        </Col>
      </Row> */}
    </>
  );
}

export default Work;

