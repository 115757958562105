import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Helmet } from "react-helmet";
import medium from './medium.png';
import './App.css'

function Writing() {
    return (
        <>
            <Helmet>
                <title>Isabelle Kohout - Writing</title>
            </Helmet>
            <Row className="pt-5">
                <Col md={{ span: 8, offset: 2 }} className='fade-in'>
                    {/* <h1 class="header-text">...words in progress...</h1> */}
                    <div class='align-center'>
                        <p>✍🏻 <a class = 'bold' href='https://medium.com/@izzykohout' target='_blank'>Medium:</a><span className='content-text'> writing about product management & tech things.</span></p>
                        <p>📝 <a class = 'bold' href='https://isabellek.substack.com' target='_blank'>Substack:</a><span className='content-text'> my personal writing. Musings, short fiction.</span></p>
                        <p>📚 <a class = 'bold' href='https://thundering-hoodie-3c1.notion.site/Reading-List-b11ea8c2f1624e0c9f1be4f1c12d30f3' target='_blank'>My reading list:</a><span className='content-text'> reading is one of my favourite things in the world. I try to read as diversely as possible - to learn, to be entertained, and to connect to beauty, history, and meaning.</span></p>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Writing;

