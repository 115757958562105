import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Helmet } from "react-helmet";
import medium from './medium.png';
import './App.css'

function Resources() {
    return (
        <>
            <Helmet>
                <title>Isabelle Kohout - Resources</title>
            </Helmet>
            <Row className="pt-5">
                <Col md={{ span: 8, offset: 2}} className='fade-in'>
                    {/* <h1 class="header-text">resources</h1> */}
                    <div class='align-center'>
                        <p className='content-text'>A collection of helpful links and resources I've curated. My hope is that they're helpful for others who are interested in similar things!</p>
                        <p>🤓 <a class = 'bold' href='https://apm-guide.notion.site/The-APM-Guide-1391d2621c0d45d59c169b6f329a442a' target='_blank'>The APM Guide:</a><span className='content-text'> a collection of my favourite resources on the Product Management role for early career peeps. Check it out if you're curious about PM!</span></p>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Resources;

